export const environment = {
  serviceSubscriptionKey: '6eec44da8c6c47008e735e19f64b3845',
  isProdEnv: false,
  production: false,
  flagging: true,
  clientId: 'e4097b8d-7e26-4bc5-919c-ccae3ea3989e',
  authDomain: 'agrisuiteDev.b2clogin.com',
  susiAuthority: 'https://agrisuiteDev.b2clogin.com/agrisuiteDev.onmicrosoft.com/B2C_1_susi_v2',
  forgotPasswordAuthority: 'https://agrisuiteDev.b2clogin.com/agrisuiteDev.onmicrosoft.com/B2C_1_reset_password_v2',
  signUpAuthority: 'https://agrisuiteDev.b2clogin.com/agrisuiteDev.onmicrosoft.com/B2C_1_signup_v2',
  i18nDirectory: './assets/i18n/',
  apiEndpointRoot: 'https://dev-agrisuite-apim.azure-api.net/',
  apiEndpointLocationV1: 'https://dev-agrisuite-apim.azure-api.net/location/api/v1/location/',
  apiEndpointWorksheetV1: 'https://dev-agrisuite-apim.azure-api.net/worksheet/api/v1/worksheet/',
  apiEndpointMaterialV1: 'https://dev-agrisuite-apim.azure-api.net/material/api/v1/material/',
  apiEndpointAGErosionV1: 'https://dev-agrisuite-apim.azure-api.net/agerosion/api/v1/agerosion/',
  apiEndpointMDSV1: 'https://dev-agrisuite-apim.azure-api.net/mds/api/v1/mds/',
  apiEndpointFlagV1: 'https://dev-agrisuite-apim.azure-api.net/common/api/v1/flag/',
  worksheetPutThrottle: 100
};
