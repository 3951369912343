<div data-emissionComponent class="fieldset flex-child flex-child--full-width">
    <table class="table">
        <!-- column headers -->
        <thead>
            <tr>
                <th scope="col" class="no-border-top">&nbsp;</th>

                <th scope="col" *ngFor="let col of columns; let last = last" class="no-border-top" [class.border-left-grey]="last">
                    {{ col?.headerKey | translate | async }}<br /><span class="gray-text">({{ "" | emissionUnitPipe: false | async }})</span>
                </th>
            </tr>
        </thead>

        <!-- rows -->
        <tbody>
            <ng-container *ngFor="let row of rows">
                <!-- divider row -->
                <ng-container *ngIf="isDividerRow(row); else dataRow">
                    <tr class="font-weight--medium">
                        <th scope="row" class="background-colour--grey-primary text-align--left">
                            {{ row?.headerKey | translate | async }}
                        </th>

                        <td *ngFor="let col of columns" class="background-colour--grey-primary" [class]="row?.classes"></td>
                    </tr>
                </ng-container>

                <!-- data row -->
                <ng-template #dataRow>
                    <tr [class.font-weight--medium]="row?.highlighted">
                        <!-- row header -->
                        <th scope="row" class="text-align--left" [class]="row?.classes?.join(' ')">
                            <!-- default -->
                            <ng-container *ngIf="isDefaultRow(row); else otherHeader">
                                <ng-container *ngIf="row?.headerKey; else headerText">
                                    {{ row?.headerKey | translate | async }}
                                </ng-container>
                                <ng-template #headerText>
                                    {{ row?.headerText }}
                                </ng-template>
                            </ng-container>

                            <ng-template #otherHeader>
                                <!-- crop -->
                                <ng-container *ngIf="isCropRow(row)">
                                    {{ row?.headerData?.cropTypeId | cropNamePipe: "TYPE" | async }},
                                    {{ row?.headerData?.cropSubTypeId | cropNamePipe: "SUB_TYPE" | async }}
                                    <span> ({{ row?.headerData?.croppingYear | croppingYearRangePipe | async }}) </span>
                                </ng-container>

                                <!-- livestock -->
                                <ng-container *ngIf="isLivestockRow(row)">
                                    {{ row?.headerData?.name }}
                                    <span>({{ row?.headerData?.numberOfLivestock | decimalPipe: 0 }}, {{ row?.headerData | livestockTypeNamePipe | async }})</span>
                                </ng-container>
                            </ng-template>
                        </th>

                        <td *ngFor="let col of columns; let last = last" [class]="row?.classes" [class.border-left-grey]="last">
                            {{ row?.data[col?.key] | emissionTextPipe: false | async }}
                        </td>
                    </tr>
                </ng-template>
            </ng-container>
        </tbody>
    </table>
</div>
